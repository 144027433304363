import React, { lazy, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

import { resetListeners } from "src/liveUpdate";
import useInvalidateAllTags from "src/hooks/useInvalidateAllTags";

import Error from "../components/error";
import { Unauthorized } from "../pages/public/unauthorized";

const Login = lazy(() => import("../pages/public/login"));
const AppLayout = lazy(() => import("../pages/private/appLayout"));

const DepositsPage = lazy(() => import("../pages/public/depositsPage"));
const SharedDepositsPage = lazy(
  () => import("../pages/public/sharedDepositPage"),
);
const Registration = lazy(() => import("../pages/public/registration"));
const TransactionPage = lazy(
  () => import("../pages/public/transactionLoginPage"),
);
const SignatureRequestPage = lazy(
  () => import("../pages/public/signatureRequestLoginPage"),
);
const SignatureRequestOneSpanPage = lazy(
  () => import("../pages/public/signatureRequestOneSpanPage"),
);
const SignatureRequestCompletedPage = lazy(
  () => import("../pages/public/signatureRequestCompletedLoginPage"),
);
const ExternalSignatureRequestPage = lazy(
  () => import("../pages/public/externalSignatureRequestLoginPage"),
);
const ExternalArchivePage = lazy(
  () => import("../pages/public/externalArchiveLoginPage"),
);
const FileTransferLoginPage = lazy(
  () => import("../pages/public/fileTransferLoginPage"),
);
const FileTransferPage = lazy(
  () => import("../pages/private/FileTransferPage"),
);

function fallbackRender({ error }) {
  return <Error status={error.status} />;
}

const Routing = () => {
  const location = useLocation();
  const invalidateAllTags = useInvalidateAllTags();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return () => {};
    App.addListener("resume", () => {
      invalidateAllTags();
    });

    return () => {
      resetListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary fallbackRender={fallbackRender} key={location.pathname}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotten_password" element={<Login />} />
        <Route path="/register/:invitationKey" element={<Registration />} />
        <Route path="*" element={<AppLayout />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/conversations/:id/deposits" element={<DepositsPage />} />
        <Route
          path="/medium-config/:id/deposits"
          element={<SharedDepositsPage />}
        />
        <Route
          path="contact/login/:transactionId"
          element={<TransactionPage />}
        />
        <Route
          path="recipient/login/:validationCodeId/external-archives/:externalArchiveId"
          element={<ExternalArchivePage />}
        />
        <Route
          path="signature-request/:signatureRequestId/completed/:participationId/recipient"
          element={<ExternalSignatureRequestPage />}
        />
        <Route
          path="file-transfers/login/:fileTransferId"
          element={<FileTransferLoginPage />}
        />
        <Route
          path="signature-request/:signatureRequestId/sign/:participationId"
          element={<SignatureRequestPage />}
        />
        <Route
          path="signature-request/:signatureRequestId/sign/:participationId/onespan"
          element={<SignatureRequestOneSpanPage />}
        />
        <Route
          path="signature-request/:signatureRequestId/completed/:participationId"
          element={<SignatureRequestCompletedPage />}
        />
        <Route
          path="contact/file-transfers/:fileTransferId"
          element={<FileTransferPage />}
        />
      </Routes>
    </ErrorBoundary>
  );
};

export default Routing;
