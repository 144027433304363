import { api } from "src/services/store/apiSlice";
import { apiEndpoints } from "src/services/utils";
import TAGS from "src/services/utils/tags";
import { ITeam, IUpdateTeamEquisoftEventTypes } from "src/types";

const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<ITeam[], void>({
      query: () => apiEndpoints.teams(),
      providesTags: [TAGS.TEAM],
    }),
    getTeamById: builder.query<ITeam, string>({
      query: (id) => apiEndpoints.team(id),
      providesTags: [TAGS.TEAM],
    }),
    updateTeamEquisoftEventTypes: builder.mutation<
      void,
      IUpdateTeamEquisoftEventTypes
    >({
      query: (data) => ({
        url: apiEndpoints.teamEquisoftEventTypes(data.id),
        method: "PATCH",
        body: data.team,
      }),
      invalidatesTags: [TAGS.TEAM],
    }),
    createDocumentTree: builder.mutation<void, string>({
      query: (id) => ({
        url: apiEndpoints.createTeamDocumentTree(id),
        method: "POST",
      }),
      invalidatesTags: [TAGS.TEAM],
    }),
  }),
});

export const {
  useLazyGetTeamByIdQuery,
  useGetTeamByIdQuery,
  useUpdateTeamEquisoftEventTypesMutation,
  useCreateDocumentTreeMutation,
} = teamApi;

export default teamApi;
