const TAGS = {
  USER: "user",
  CONVERSATION: "conversation",
  CONVERSATION_EVENT: "conversationEvent",
  CONVERSATION_ARCHIVE: "conversationArchive",
  MEMBERSHIP: "membership",
  TEAM: "team",
  CONTACT: "contact",
  CONTACT_FILE: "contactFile",
  DID: "did",
  INVITATION: "invitation",
  TRANSACTION: "transaction",
  SIGNATURE_REQUEST: "signatureRequest",
  SIGNATURE_REQUEST_SIGNEE: "signatureRequestSignee",
  SIGNATURE_REQUEST_FILE_FIELD_SIGNEE: "signatureRequestFileFieldSignee",
  SIGNATURE_REQUEST_FILE: "signatureRequestFile",
  SIGNATURE_REQUEST_FILE_FIELD: "signatureRequestFileField",
  SIGNATURE_REQUEST_WAVE: "signatureRequestWave",
  SIGNATURE_REQUEST_WAVE_RECIPIENT: "signatureRequestWaveRecipient",
  SIGNATURE_REQUEST_WAVE_PARTICIPATION: "signatureRequestWaveParticipation",
  SIGNATURE_REQUEST_ARCHIVE_DOCUMENT: "signatureRequestArchiveDocument",
  SIGNATURE_REQUEST_ARCHIVE_SHAREPOINT_DOCUMENT:
    "signatureRequestArchiveSharepointDocument",
  RECIPIENT: "recipient",
  EXTERNAL_ARCHIVE: "externalArchive",
  FILE_TRANSFER: "fileTransfer",
  EQUISOFT_DOCUMENT: "equisoftDocument",
  PHONE_RECORDING: "phoneRecording",
  PHONE_RECORDING_ARCHIVE_DOCUMENT: "phoneRecordingArchiveDocument",
  PHONE_RECORDING_ARCHIVE_SHAREPOINT_DOCUMENT:
    "phoneRecordingArchiveSharepointDocument",
  EVENT_TYPES: "eventTypes",
  DOCUMENT_TYPES: "documentTypes",
  EQUISOFT_CONNECT_ME: "equisoftConnectMe",
} as const;

export default TAGS;
