import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface ITeamNotification {
  teamId: string;
  count: number;
}

export interface IState {
  drawerIsOpen: boolean;
  teamSocket: WebSocket | null;
  teamsNotification: ITeamNotification[];
  equisoftBrokenModalDismissed: boolean;
}

const initialState: IState = {
  drawerIsOpen: false,
  teamSocket: null,
  teamsNotification: [],
  equisoftBrokenModalDismissed: false,
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setTeamDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },
    setTeamsNotification: (
      state,
      action: PayloadAction<ITeamNotification[]>,
    ) => {
      state.teamsNotification = action.payload;
    },
    incrementTeamNotifications: (state, action: PayloadAction<string>) => {
      state.teamsNotification.forEach((team, index) => {
        if (team.teamId === action.payload) {
          state.teamsNotification[index].count += 1;
        }
      });
    },
    decrementTeamNotifications: (
      state,
      action: PayloadAction<{ teamId: string; amount: number }>,
    ) => {
      state.teamsNotification.forEach((team, index) => {
        if (team.teamId === action.payload.teamId) {
          state.teamsNotification[index].count -= action.payload.amount;
        }
      });
    },
    dismissEquisoftBrokenModal: (state) => {
      state.equisoftBrokenModalDismissed = true;
    },
  },
});

export const {
  setTeamDrawer,
  setTeamsNotification,
  incrementTeamNotifications,
  decrementTeamNotifications,
  dismissEquisoftBrokenModal,
} = teamSlice.actions;
export default teamSlice.reducer;
