import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

interface ISignatureRequestSliceState {
  updateSignatureRequestDrawerIsOpen: boolean;
  updateSignatureRequestId: string;
  isCompletingWave: boolean;
}

const initialState: ISignatureRequestSliceState = {
  updateSignatureRequestDrawerIsOpen: false,
  updateSignatureRequestId: "",
  isCompletingWave: false,
};

const signatureRequestSlice = createSlice({
  name: "signatureRequest",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setUpdateSignatureRequestDrawerIsOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.updateSignatureRequestDrawerIsOpen = action.payload;
    },
    setUpdateSignatureRequestId: (state, action: PayloadAction<string>) => {
      state.updateSignatureRequestId = action.payload;
    },
    setIsCompletingWave: (state, action: PayloadAction<boolean>) => {
      state.isCompletingWave = action.payload;
    },
  },
});

export const {
  setUpdateSignatureRequestDrawerIsOpen,
  setUpdateSignatureRequestId,
  setIsCompletingWave,
} = signatureRequestSlice.actions;
export default signatureRequestSlice.reducer;
