import Box from "@mui/material/Box";
import React, {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
} from "react";
import "./FileInput.scss";

export interface IFileInputProps extends ComponentPropsWithoutRef<"input"> {
  label: string | React.ReactNode;
  htmlFor?: string;
  height?: string;
  width?: string;
  pdfOnly?: boolean;
  disabled?: boolean;
}

const FileInput = forwardRef(
  (
    {
      label,
      htmlFor,
      height,
      width = "100%",
      pdfOnly,
      disabled = false,
      ...props
    }: IFileInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const accept = pdfOnly ? ".pdf" : "";

    return (
      <Box sx={{ width, height }}>
        <label htmlFor={htmlFor} className="FileInput">
          {label}
          <input
            {...props}
            ref={ref}
            type="file"
            multiple
            accept={accept}
            disabled={disabled}
          />
        </label>
      </Box>
    );
  },
);

export default FileInput;
