import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "src/app/hooks";
import { api } from "src/services/store/apiSlice";
import { resetAll } from "src/app/actions";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (redirectUrl = "/login") => {
    const teamFilters = localStorage.getItem("teamFilters");
    localStorage.clear();
    if (teamFilters !== null) {
      localStorage.setItem("teamFilters", teamFilters);
    }
    dispatch(api.util?.resetApiState());
    dispatch(resetAll());
    navigate(redirectUrl);
  };
};
