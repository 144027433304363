import { api } from "src/services/store/apiSlice";
import { apiEndpoints } from "src/services/utils";
import TAGS from "src/services/utils/tags";
import { IUser, IUserCreatePost, IUserPost } from "src/types/IUser";

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<IUser, { shouldRedirect?: boolean } | void>({
      query: (params) => ({
        url: apiEndpoints.authUser(),
        params: { ...params },
      }),
      providesTags: [TAGS.USER],
    }),
    createUser: builder.mutation<IUser, IUserCreatePost>({
      query: (data) => ({
        url: apiEndpoints.registration(),
        method: "POST",
        body: data,
      }),
    }),
    updateUser: builder.mutation<IUser, IUserPost>({
      query: ({ id, ...data }) => ({
        url: apiEndpoints.authUser(),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [TAGS.USER];
        }
        return [];
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
} = userApi;

export default userApi;
