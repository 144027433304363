import { useAppDispatch } from "src/app/hooks";
import { api } from "src/services/store/apiSlice";
import { resetConversationsPagination } from "src/services/store/conversationSlice";
import TAGS from "src/services/utils/tags";

type TagsType = typeof TAGS;
type TagType = TagsType[keyof TagsType];

interface UseInvalidateAllTags {
  skipTags?: TagType[];
}

export default function useInvalidateAllTags() {
  const dispatch = useAppDispatch();
  return ({ skipTags = [] }: UseInvalidateAllTags = {}) => {
    const tagsToInvalidate = Object.values(TAGS).filter(
      (tag) => !skipTags.includes(tag),
    );
    dispatch(resetConversationsPagination());
    dispatch(api.util.invalidateTags(tagsToInvalidate));
  };
}
