import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { DEBUG_I18NEXT } from "./constants";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    debug: DEBUG_I18NEXT,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    ns: [
      "auth",
      "chat",
      "common",
      "contact",
      "conversation",
      "deposit",
      "phoneRecording",
      "registration",
      "settings",
      "shared",
      "signature",
      "transaction",
      "user",
    ],
  });

export default i18n;
